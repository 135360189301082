import React from "react"

const Notification = () => (
  <>
    <h3>
      Bitte für termine <a href="tel:016092387165">0160 923 87165</a> anrufen.
      Danke!
    </h3>
    <hr />
  </>
)

export default Notification
